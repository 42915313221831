<template>
  <v-container fluid class="px-lg-5">
    <app-modal
      v-show="isModalVisible"
      :modal="modal"
      @submit-modal="submitModal"
    />
    <app-header
      :title="title"
      :breadcrumbs="breadcrumbs"
      :actionButton="actionButton"
      @clicked="openCreateDepartmentModal"
      :showButton="true"
    />
    <v-card class="rounded-card" flat outlined>
      <v-data-table
        :headers="headers"
        :items="departments.items"
        :options.sync="options"
        @update:options="updateOptions"
        :server-items-length="departments.count"
        :loading="loading"
        :no-data-text="$t('messages.emptyState', { entity: $tc('models.department.entity') })"
        :no-results-text="$t('messages.emptyState', { entity: $tc('models.department.entity') })"
        flat
        :style="{ cursor: isAdmin ? '' : 'pointer' }"
      >
        <template v-slot:[`item.description`]="{ item }">
          <span class="row">{{ item.description }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon small @click="editDepartment(item)">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
          <v-btn icon small @click="deleteDepartment(item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  data() {
    return {
      loading: false,
      isModalVisible: false,
      modal: {
        show: false,
        id: '',
        behaviour: '',
        resetForm: false,
        title: '',
        submitButton: {
          label: this.$t('actions.save')
        },
        fields: {
          name: {
            type: 'text',
            key: 'name',
            value: '',
            label: this.$t('models.department.attributes.name'),
            rules: [
              {
                name: 'required'
              }
            ],
            classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
            serverErrors: []
          },
          description: {
            type: 'textarea',
            key: 'description',
            value: '',
            label: this.$t('models.department.attributes.description'),
            classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
            serverErrors: []
          }
        }
      },
      title: {
        text: this.$t('layout.mainMenu.departments'),
        icon: 'mdi-office-building'
      },
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.company'),
          to: {name: 'Departments'},
          exact: true
        },
        {
          text: this.$t('layout.mainMenu.departments'),
          to: {name: 'Departments'},
          exact: true
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      },
      actionButton: {
        text: this.$t('actions.create', {entity: this.$tc('models.department.entity', 1)}),
        icon: 'mdi-plus'
      },
      headers: [
        {
          text: '',
          value: 'id',
          align: ' d-none'
        },
        {
          text: this.$t('models.department.attributes.name'),
          value: 'name'
        },
        {
          text: this.$t('models.department.attributes.description'),
          value: 'description'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '120px'
        }
      ]
    };
  },
  computed: mapGetters(['departments', 'isAdmin']),
  methods: {
    ...mapActions([
      'getDepartments',
      'destroyDepartment',
      'createDepartment',
      'updateDepartment'
    ]),
    updateOptions(options) {
      this.loading = true;
      this.getDepartments(options).then(() => (this.loading = false));
    },
    submitModal(data) {
      let form;
      let request;
      switch (this.modal.behaviour) {
      case 'createDepartment':
        request = this.createDepartment(data);
        break;
      case 'updateDepartment':
        form = {
          departmentId: this.modal.id,
          form: data
        };
        request = this.updateDepartment(form);
        break;
      }
      request.then((response) => {
        if (this.successCode(response.status)) {
          this.modal.show = false;
          this.updateOptions(this.options);
        } else {
          this.displayInlineServerErrors(this.modal.fields, response.errors);
        }
      });
    },
    editDepartment(data) {
      this.clearInlineServerErrors(this.modal.fields);
      this.modal.behaviour = 'updateDepartment';
      this.modal.title = this.$t('views.departments.updateDepartment');
      this.modal.fields.name.value = data.name;
      this.modal.fields.description.value = data.description;
      this.modal.id = data.id;
      this.modal.show = true;
    },
    openCreateDepartmentModal() {
      this.modal.title = this.$t('views.departments.newDepartment');
      this.modal.resetForm = true;
      this.modal.fields.name.value = '';
      this.modal.fields.description.value = '';
      this.modal.behaviour = 'createDepartment';
      this.modal.show = true;
      this.$nextTick(() => {
        this.modal.resetForm = false;
      });
    },
    deleteDepartment(department) {
      this.$confirm(
        this.$t('messages.confirmation.delete', {entity: department.name}),
        {
          buttonTrueText: this.$t('actions.confirm'),
          buttonFalseText: this.$t('actions.cancel')
        }
      ).then((confirmed) => {
        if (confirmed) {
          this.destroyDepartment(department.id).then((status) => {
            if (this.successCode(status)) {
              this.updateOptions(this.options);
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.row {
  display: table;
  table-layout: fixed;
  word-wrap: break-word;
  width: 100%;
}
</style>
